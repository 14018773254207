import logo from './logo.svg';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Directory from "./Directory";
import AboutUs from "./about_us";
import StatementOfFaith from "./statement_of_faith";
import History from "./history";
import ChurchLeaders from "./church_leaders";
import Heartland from "./heartland";
import WhatToExpect from "./what_to_expect";
import YouthNight from "./youth_night";
import NewHopeMissions from "./new_hope_missions";
import RanaesBaskets from "./ranaes_baskets";
import VisitUs from "./visit_us";
import ContactUs from "./contact_us";
import Sermons from "./sermons";
import BibleMemory from "./bible_memory";
import {load_fetch} from "./pr_utils";
import {useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";
import PrintDirectory from "./print_directory";
import Testimony from "./testimony";
import Calendar from "./Calendar";

function App() {
  const [authStatus, setAuthStatus] = useState(false);
  const authenticationStatus = {authStatus, setAuthStatus};
  const [userInfo, setUserInfo] = useState(false);
  const [canViewDirectory, setCanViewDirectory] = useState(false);

  useEffect(() => {
    check_authentication_status();
  }, []);

  useEffect(() => {
    //console.log(authStatus);
  }, [authStatus])

  const check_authentication_status = () => {
    let url = 'Security_Handler.php';
    let postData = {
      operation: 'CHECK_AUTHENTICATION_STATUS'
    }
    load_fetch(url, postData, (data) => {
        console.log("Checking Auth Status");
      if(!data['ERROR'] && !data['error']) {
          setUserInfo(data);
          if(data['role'] === 'ADMIN' ||
              data['role'] === 'DIRECTORY_EDITOR' ||
              data['role'] === 'DIRECTORY_VIEWER') {
              setCanViewDirectory(true);
          }
          //console.log("Logged in.");
          setAuthStatus(data);
      } else {
          //console.log("Not logged in.");
          setAuthStatus(false);
      }
    });
  }

  const clear_login_information = () => {
      setAuthStatus(false);
  }
  const update_user_info = (new_info) => {
      setUserInfo(new_info);
  }

  if(window.location.pathname.indexOf("print_directory") !== -1) {
      return (
          <AuthContext.Provider value={authenticationStatus}>
          <BrowserRouter>
              <Routes>
                  <Route
                      path="/print_directory"
                      element={<PrintDirectory can_view_directory={canViewDirectory} />} />
              </Routes>
          </BrowserRouter>
          </AuthContext.Provider>
      )
        //return <Navigate to='./pr_api/Print_Directory.php' replace={true} />

  } else {
      return (
          <AuthContext.Provider value={authenticationStatus}>
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Layout update_user_info={update_user_info}
                                                       app_logout_function={clear_login_information}
                                                       canViewDirectory={canViewDirectory}
                      />}>
                          <Route index element={<Home page_title="Home"/>}/>
                          <Route path="about_us" element={<AboutUs page_title="About Us"/>}/>
                          <Route path="directory"
                                 element={<Directory user_info={userInfo} page_title="Church Directory"/>}/>
                          <Route path="statement_of_faith"
                                 element={<StatementOfFaith page_title="Statement of Faith"/>}/>
                          <Route path="history" element={<History page_title="Church History"/>}/>
                          <Route path="church_leaders" element={<ChurchLeaders page_title="Church Leaders"/>}/>
                          <Route path="heartland"
                                 element={<Heartland page_title="Heartland Association of Churches"/>}/>
                          <Route path="what_to_expect" element={<WhatToExpect page_title="What to Expect"/>}/>
                          <Route path="youth_night" element={<YouthNight page_title="Youth Night"/>}/>
                          <Route path="new_hope_missions" element={<NewHopeMissions page_title="New Hope Missions"/>}/>
                          <Route path="ranaes_baskets" element={<RanaesBaskets page_title="Ranae's Baskets"/>}/>
                          <Route path="visit_us" element={<VisitUs page_title="Visit Us"/>}/>
                          <Route path="contact_us" element={<ContactUs page_title="Contact Us"/>}/>
                          <Route path="sermons" element={<Sermons page_title="Sermons"/>}/>
                          <Route path="bible_memory" element={<BibleMemory page_title="Bible Memory"/>}/>
                          <Route path="testimony" element={<Testimony page_title="Testimony"/>}/>
                          <Route path="calendar" element={<Calendar page_title="Calendar"/>}/>
                      </Route>
                  </Routes>
              </BrowserRouter>
          </AuthContext.Provider>
      );
  }
}

export default App;

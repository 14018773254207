import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import OneSermonView from "./one_sermon_view";
import {load_fetch} from "./pr_utils";
import SermonListItem from "./sermon_list_item";

const Sermons = () => {
    const [mostRecentData, setMostRecentData] = useState(false);
    const [allSermonData, setAllSermonData] = useState([]);
    const [allSermons, setAllSermons] = useState(false);

    useEffect(() => {
        // get_most_recent_sermon();
        // get_all_but_most_recent_sermon();
        get_all_sermons();
    }, []);
    const get_all_sermons = () => {
        let url = 'Sermon_Handler.php';
        let postData = {
            operation: 'GET_ALL_SERMONS'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setAllSermons(data);
            } else {
                console.log(data);
            }
        });
    }
    // const get_most_recent_sermon = () => {
    //     let url = 'Sermon_Handler.php';
    //     let postData = {
    //         operation: 'GET_MOST_RECENT_SERMON'
    //     }
    //     load_fetch(url, postData, (data) => {
    //         if(!data['ERROR'] && !data['error']) {
    //             //console.log(data);
    //             setMostRecentData(data);
    //         } else {
    //             console.log(data);
    //         }
    //     });
    // }
    // const get_all_but_most_recent_sermon = () => {
    //     let url = 'Sermon_Handler.php';
    //     let postData = {
    //         operation: 'GET_ALL_BUT_MOST_RECENT_SERMON'
    //     }
    //     load_fetch(url, postData, (data) => {
    //         if(!data['ERROR'] && !data['error']) {
    //             //console.log(data);
    //             setAllSermonData(data);
    //         } else {
    //             console.log(data);
    //         }
    //     });
    // }

    return (<div>
        <div className={Styles.pr_heading_large}>Most Recent</div>
        {allSermons && (
            <>
                <OneSermonView data={allSermons[0]} />
            </>
        )}
        {allSermons && (
            allSermons.slice(1, 2).map((item, index) => (
                <OneSermonView data={item} key={index} />
            )))}


        <div className={Styles.pr_heading_large}>All Sermons</div>
        <table>
            <thead>
                <tr>
                    <td>Date</td>
                    <td>Title</td>
                    <td>Verses</td>
                    <td>Description</td>
                    <td>Spotify</td>
                    <td>Rumble Link</td>
                    <td>YouTube Link</td>
                </tr>
            </thead>
            <tbody>
            {allSermons && (
                allSermons.map((item, index) => (
                    <SermonListItem data={item} key={index} />
                )))}
            </tbody>
        </table>
    </div>)
}
export default Sermons;
import React from "react";
import Slideshow from "./slideshow";
import Styles from "./styles/pr.module.css"
import WorshipServiceImage from "./images/WorshipService-1024x1024.jpg";
import SmallGroupImage from "./images/smallgroupbiblestudy-1024x1024.jpg";
import VisitorImage from "./images/visitor-1024x1024.jpg";
import VBS2024 from "./images/vbs2024.png";
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div className={Styles.pr_home_wrapper}>
            <h2>Welcome to Prairie Ridge Church</h2>
            <div className={Styles.pr_home_info_row}>
                <img src={VBS2024} />
            </div>
            <Slideshow hide_controls={true} />
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>Our Sunday service starts at 10:30 am and usually lasts about an hour.</div>
                <div><img className={Styles.pr_home_info_image} src={WorshipServiceImage} /></div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    We have Small Group Bible Study at 9:30 am.
                </div>
                <div><img className={Styles.pr_home_info_image} src={SmallGroupImage} /></div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    First time visitor, or looking for a church? Take a look at <Link to="what_to_expect">What to Expect</Link>.
                </div>
                <div><img className={Styles.pr_home_info_image} src={VisitorImage} /></div>
            </div>
        </div>
    )
}
export default Home;